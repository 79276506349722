import { Box, Checkbox, Drawer, FormControl, FormLabel, IconButton, Stack } from '@mui/joy';
import { graphql, PageProps, useStaticQuery } from 'gatsby';
import React, { useState } from 'react';
import { FaGear } from 'react-icons/fa6';
import { useIdentity } from './providers/IdentityProvider';
import { useSettings } from './providers/SettingsProvider';

export interface FooterProps {
  location?: PageProps['location'];
}

const Footer: React.FC<FooterProps> = ({ location }) => {
  const { isLoggedIn } = useIdentity();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { settings, updateSettings } = useSettings();
  const data = useStaticQuery<Queries.FooterQuery>(graphql`
    query Footer {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <footer>
      <Stack
        direction="column"
        gap={0.25}
        sx={{ p: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Box>
          © {new Date().getFullYear()} {data.site?.siteMetadata?.title}
        </Box>

        {isLoggedIn && (
          <IconButton onClick={() => setIsDrawerOpen(true)}>
            <FaGear size="14" />
          </IconButton>
        )}
      </Stack>

      <Drawer open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
        <Box p={1}>
          <FormControl>
            <Stack direction="row" gap={0.25} alignItems="center">
              <Checkbox
                checked={settings.showVerticalRhythm}
                onChange={(e) => updateSettings({ showVerticalRhythm: e.target.checked })}
              />
              <FormLabel>Show vertical rhythm?</FormLabel>
            </Stack>
          </FormControl>
        </Box>
      </Drawer>
    </footer>
  );
};

export default Footer;
