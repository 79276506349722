import { Box, Container, Link, Stack, Typography } from '@mui/joy';
import { visuallyHidden } from '@mui/utils';
import { Link as GatsbyLink, PageProps } from 'gatsby';
import React from 'react';
import useSiteMetadata from '../hooks/useSiteMetadata';
import { FontFamily, Weight } from '../styles/theme';

export interface HeaderProps {
  location?: PageProps['location'];
}

const navigationLinks = [
  {
    name: 'Writing',
    link: '/writing',
  },
  {
    name: 'Projects',
    link: '/creating',
  },
];

const Header: React.FC<HeaderProps> = ({ location }) => {
  const metadata = useSiteMetadata();

  return (
    <Container maxWidth="xl" component="header" sx={{ mb: 'calc(5vw - 20px)' }}>
      <Stack
        direction={['column', 'row']}
        alignItems="center"
        justifyContent="space-between"
        gap={2}
        pt={3}
        pb={2}
      >
        <Link
          level="h2"
          component={GatsbyLink}
          to="/"
          underline="none"
          fontFamily={FontFamily.StyleScript}
          fontWeight={Weight.xs}
          fontSize="xl4"
          sx={{
            opacity: 1,
            transform: 'translateY(6px)',
            '&:hover': {
              opacity: 0.7,
            },
          }}
        >
          <Typography component="h1" fontWeight="sm">
            Mike Fowler
          </Typography>
        </Link>

        <Box component="nav" sx={metadata.settings?.hideNavigation ? visuallyHidden : {}}>
          <Typography level="h2" sx={visuallyHidden}>
            Main navigation
          </Typography>
          <Stack direction="row" gap={2} component="menu">
            {navigationLinks?.map(({ name, link }) => (
              <Box component="li" key={`${name}-${link}`} sx={{ listStyle: 'none' }}>
                <Link component={GatsbyLink} to={link} color="neutral" fontSize="lg">
                  <Typography fontFamily="display">{name}</Typography>
                </Link>
              </Box>
            ))}
          </Stack>
        </Box>
      </Stack>
    </Container>
  );
};

export default Header;
