import netlifyIdentity from 'netlify-identity-widget';

import '@fontsource-variable/alegreya';
import '@fontsource-variable/dm-sans';
import '@fontsource-variable/montserrat';
import '@fontsource-variable/source-sans-3';
import '@fontsource-variable/work-sans';
import '@fontsource/dm-serif-display';
import '@fontsource/kanit';
import '@fontsource/merriweather/400.css';
import '@fontsource/merriweather/700.css';
import '@fontsource/montez';
import '@fontsource/palanquin/100.css';
import '@fontsource/palanquin/200.css';
import '@fontsource/palanquin/300.css';
import '@fontsource/palanquin/400.css';
import '@fontsource/palanquin/500.css';
import '@fontsource/palanquin/600.css';
import '@fontsource/palanquin/700.css';
import '@fontsource/style-script';
import 'prismjs/themes/prism.css';

import './src/styles/style.css';

netlifyIdentity.init();

export { default as wrapPageElement } from './src/wrapPageElement';
export { default as wrapRootElement } from './src/wrapRootElement';
