import { graphql, useStaticQuery } from 'gatsby';

const useSiteMetadata = () => {
  const data = useStaticQuery<Queries.SiteMetadataQuery>(graphql`
    query SiteMetadata {
      site {
        siteMetadata {
          title
          description
          siteUrl
          social {
            github
            instagram
          }
          settings {
            hideNavigation
          }
        }
      }
    }
  `);

  console.log({ data });

  return data.site?.siteMetadata ?? ({} as Queries.SiteSiteMetadata);
};

export default useSiteMetadata;
