import { Box } from '@mui/joy';
import { PageProps } from 'gatsby';
import React, { PropsWithChildren } from 'react';
import Footer from '../Footer';
import Header from '../Header';
import SiteWrapper from '../SiteWrapper';

export interface LayoutProps<DataType = object, PageContextType = object>
  extends PropsWithChildren,
    Omit<PageProps, 'children'> {}

const Layout: React.FC<LayoutProps> = ({ children, location }) => {
  return (
    <SiteWrapper>
      <Header location={location} />

      <Box component="main" sx={{ flex: 1 }}>
        {children}
      </Box>

      <Footer location={location} />
    </SiteWrapper>
  );
};

export default Layout;
